<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row >
      <v-col
        v-if="partner_mh"
        md="12"
        lg="6">
        <LatestOrders
          :orders="latestOrders"
          :loading="loading"
        />
      </v-col>
      <v-col
        v-if="partner_me"
        md="12"
        lg="6">
        <LatestConsultingAppointments
          :consultingAppointments="consultingAppointments"
          :loading="loading"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="12">
        <OrdersInProgress
          :orders="ordersInProgress"
          :loading="loading"
        />
      </v-col>
    </v-row>
    <v-row v-if="isSuperAdmin">
      <v-col
        md="12">
        <material-card color="primary">
          MeinElektromobil Re-Deployment:<br>
          <v-btn
            @click="redeployMe"
            type="button"
            color="primary"
            :disabled="isRedeploying">
            Re-Deployment anstoßen
          </v-btn>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import DashboardAPI from '@/api/dashboard'
import LatestConsultingAppointments from '@/components/dashboard/LatestConsultingAppointments.vue'
import LatestOrders from '@/components/dashboard/LatestOrders.vue'
import OrdersInProgress from '../components/dashboard/OrdersInProgress.vue'

export default {
  data() {
    return {
      isRedeploying: false,
      loading: false,
      consultingAppointments: null,
      latestOrders: null,
      ordersInProgress: null,
    }
  },
  created() {
    this.loadDashboard()
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: 'auth/isSuperAdmin',
      partner_me: 'auth/partner_me',
      partner_mh: 'auth/partner_mh',
    }),
  },
  methods: {
    loadDashboard() {
      this.loading = true
      DashboardAPI.getLatestInformation().then((response) => {
        this.loading = false
        this.consultingAppointments = response.consulting_appointments
        this.latestOrders = response.latestOrders
        this.ordersInProgress = response.ordersInProgress
      })
    },
    complete(index) {
      this.list[index] = !this.list[index]
    },
    redeployMe() {
      if (this.isRedeploying) {
        return
      }
      this.isRedeploying = true
      DashboardAPI.redeployMe().then(() => {
        alert('MeinElektromobil wird neu deployed!')
        this.isRedeploying = false
      }).catch(() => {
        alert('Etwas schlug fehl. Bitte versuchen Sie es später erneut.')
        this.isRedeploying = false
      })
    },
  },
  components: {
    OrdersInProgress,
    LatestOrders,
    LatestConsultingAppointments,
  },
}
</script>
