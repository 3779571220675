import base from '@/api/base'

export default {
  getLatestInformation() {
    return base.get('/me/backend/dashboard')
  },
  redeployMe() {
    return base.get('/me/backend/redeploy')
  },
}
